<template>
  <div class="mbl-menu">
    <div class="mbl-menu-desktop">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-2 d-none d-lg-block">
            <img alt="logo" src="~/mlearn/icons/menu/mobiedu_white.png" class="logo pointer" @click="goToPage('/')">
          </div>
          <div class="d-none d-lg-block" :class="isDesktop ? 'col-5' : 'col-6'">
            <div class="list-menu d-flex align-items-center justify-content-center">
              <nuxt-link to="/hoi-bai" class="menu-item nuxt-link-exact-active" :class="{'color-active': homeActive}">Hỏi bài</nuxt-link>
              <b-dropdown class="menu-item" menu-class="custom-dropdown" toggle-class="custom-toggle" size="sm">
                <template #button-content>
                  <span :class="{'text-active': noiQuy}">Nội quy</span> <i :class="noiQuy ? 'fa fa-chevron-down custom-icon-down icon-active' : 'fa fa-chevron-down custom-icon-down'"></i>
                </template>
                <b-dropdown-item class="custom-dropdown-item">
                  <nuxt-link to="/bang-xep-hang" class="text-menu">Bảng xếp hạng</nuxt-link>
                </b-dropdown-item>
                <b-dropdown-divider class="custom-divider"></b-dropdown-divider>
                <b-dropdown-item class="custom-dropdown-item">
                  <nuxt-link to="/noi-quy-hoi-bai" class="text-menu">Nội quy hỏi bài</nuxt-link>
                </b-dropdown-item>
                <b-dropdown-divider class="custom-divider"></b-dropdown-divider>
                <b-dropdown-item class="custom-dropdown-item">
                  <nuxt-link to="/huong-dan-cong-diem" class="text-menu">Cơ chế cộng điểm</nuxt-link>
                </b-dropdown-item>
              </b-dropdown>
              <nuxt-link to="/gioi-thieu" class="menu-item nuxt-link-exact-active" :class="{'color-active': videoActive}">Giới thiệu</nuxt-link>
              <!--              <nuxt-link to="/tai-lieu" class="menu-item text-menu" :class="{'nuxt-link-exact-active': documentActive}">Giới thiệu</nuxt-link>-->
              <!--                            <nuxt-link to="/trac-nghiem" class="menu-item" :class="{'nuxt-link-exact-active': quizActive}">Bộ đề</nuxt-link>-->
              <nuxt-link to="/blog" class="menu-item nuxt-link-exact-active" :class="{'color-active': blogActive}">Blog</nuxt-link>
              <!--              <nuxt-link to="/goi-cuoc" class="menu-item " :class="{'nuxt-link-exact-active': packActive}">Gói cước</nuxt-link>-->
            </div>
          </div>
          <!--          :class="{'d-lg-flex justify-content-end': user, 'd-lg-block': !user}"-->
          <div class="col-4 text-right d-lg-flex justify-content-end group-desktop" :class="isDesktop ? 'col-5' : 'col-4'">
            <!--            <ClassList class="dropdown-verify-class d-none d-inline-flex mr-3" :all="false" placeholder="Bạn học lớp" :selected-id="interestedClass" @findClass="findClass"/>-->
<!--            <div class="search-block position-relative">-->
<!--              <span class="position-icon" @click="searchData">-->
<!--                <i class="fa fa-search pointer"></i>-->
<!--              </span>-->
<!--              <input type="text" placeholder="Tìm kiếm" class="input-search" v-model="keyword" @keyup.enter="searchData">-->
<!--            </div>-->
            <template v-if="!user">
              <!--              <img alt="profile" src="~/mlearn/icons/menu/profile.svg" class="profile">-->
              <a class="register pointer" @click="handleRedirect">Đăng ký</a>
              <!--              <span class="split">|</span>-->
              <a class="login pointer" @click="handleRedirectLogin">Đăng nhập</a>
            </template>
            <template v-else>
              <Notify class="notify"/>
              <Profile/>
            </template>
          </div>
          <div class="text-left col-4 d-block d-lg-none">
            <img alt="logo" src="~/mlearn/icons/menu/logo-Mobi.svg" class="logo-mobile pointer" @click="goToPage('/')">
          </div>
          <div class="text-right col-3 d-block d-lg-none">
<!--            <img src="~/mlearn/icons/menu/search.svg" @click="goToPage('/tim-kiem')" class="mr-3" :class="{'d-none': $route.name === 'tim-kiem'}" alt="">-->
            <i class="fa fa-search mr-3 icon-mobile" @click="goToPage('/tim-kiem')" :class="{'d-none': $route.name === 'tim-kiem'}"></i>
<!--            <img src="~/mlearn/icons/menu/open-menu.svg" class="pointer" @click.stop="openMenu" alt="">-->
            <i class="fa fa-bars pointer icon-mobile" @click.stop="openMenu"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="mbl-menu-mobile min-vh-100 overflow-auto to-left" :class="{'open':showMenu}">
<!--      <img src="~/mlearn/icons/menu/close-menu.svg" class="pointer position-absolute close-menu" @click.stop="closeMenu" alt="">-->
      <i class="fa fa-times pointer position-absolute close-menu" @click.stop="closeMenu"></i>
      <div class="list-menu">
        <div class="menu-item pl-3" v-if="user">
          <div class="form-data align-items-center" @click.prevent="goToPage('/thong-tin-ca-nhan')">
            <el-image class="avatar rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar"></el-image>
            <div class="content">
              <div class="user-name display-html display-1-line">{{ user.fullName }}</div>
              <div class="user-info">Xem thông tin cá nhân</div>
            </div>
          </div>
        </div>
        <div class="menu-item menu" :class="homeActive ? 'color-active': ''">
          <div @click.prevent="goToPage('/')">Hỏi bài</div>
        </div>
        <button v-b-toggle.collapse-1 class="menu-item menu custom-btn-collapse">
          <span :class="{'text-active': noiQuy}">Nội quy</span> <i :class="noiQuy ? 'fa fa-chevron-down custom-icon-down icon-active' : 'fa fa-chevron-down custom-icon-down'"></i>
        </button>
        <b-collapse id="collapse-1" class="custom-collapse">
          <div class="item-collapse mb-2 pointer" @click.prevent="goToPage('/bang-xep-hang')">
            <img src="~/mlearn/icons/menu/profile/rank.svg" alt="Bảng xếp hạng" class="mr-2"/>
            Bảng xếp hạng
          </div>
          <div class="item-collapse mb-2 pointer" @click.prevent="goToPage('/noi-quy-hoi-bai')">
            <img src="~/mlearn/icons/menu/profile/rules.svg" alt="Nội quy hỏi bài" class="mr-2"/>
            Nội quy hỏi bài
          </div>
          <div class="item-collapse mb-2 pointer" @click.prevent="goToPage('/huong-dan-cong-diem')">
            <img src="~/mlearn/icons/menu/profile/my-point.svg" alt="Cơ chế cộng điểm" class="mr-2"/>
            Cơ chế cộng điểm
          </div>
        </b-collapse>
        <div class="menu-item menu pointer" :class="videoActive ? 'color-active': ''">
          <div @click.prevent="goToPage('/gioi-thieu')">Giới thiệu</div>
        </div>
<!--        <div class="menu-item menu" :class="documentActive ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/tai-lieu')">Tài liệu</div>-->
<!--        </div>-->
<!--        <div class="menu-item menu" :class="quizActive ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/trac-nghiem')">Bộ đề</div>-->
<!--        </div>-->
        <div class="menu-item menu pointer" :class="blogActive ? 'color-active': ''">
          <div @click.prevent="goToPage('/blog')">Blog</div>
        </div>
        <div v-if="authenticated" class="menu-item line"></div>
        <div v-if="authenticated" class="menu-item menu pointer" :class="historyScore ? 'color-active': ''">
          <div @click.prevent="goToPage('/hoi-bai-cua-toi')">Hỏi bài của tôi</div>
        </div>
        <div v-if="authenticated" class="menu-item menu pointer" :class="historyScore ? 'color-active': ''">
          <div @click.prevent="goToPage('/lich-su-cong-diem')">Lịch sử cộng điểm</div>
        </div>
<!--        <div class="menu-item menu" :class="packActive ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/goi-cuoc')">Gói cước</div>-->
<!--        </div>-->
<!--        <div class="menu-item line"></div>-->
<!--        <div class="menu-item" :class="routeName === 'bang-xep-hang' ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/bang-xep-hang')" class="d-flex align-items-baseline">-->
<!--            <img src="~/mlearn/icons/menu/profile/rank.svg" alt="Bảng xếp hạng" class="mr-2"/>-->
<!--            Bảng xếp hạng-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="menu-item" :class="routeName === 'noi-quy-hoi-bai' ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/noi-quy-hoi-bai')" class="d-flex align-items-baseline">-->
<!--            <img src="~/mlearn/icons/menu/profile/rules.svg" alt="Nội quy hỏi bài" class="mr-2"/>-->
<!--            Nội quy hỏi bài-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="menu-item" :class="routeName === 'huong-dan-cong-diem' ? 'active': ''">-->
<!--          <div @click.prevent="goToPage('/huong-dan-cong-diem')" class="d-flex align-items-baseline">-->
<!--            <img src="~/mlearn/icons/menu/profile/my-point.svg" alt="Cơ chế cộng điểm" class="mr-2"/>-->
<!--            Cơ chế cộng điểm-->
<!--          </div>-->
<!--        </div>-->
        <div class="menu-item line"></div>
        <template v-if="user">
<!--          <div class="menu-item" :class="routeName === 'hoi-bai-cua-toi' ? 'active': ''">-->
<!--            <div @click.prevent="goToPage('/hoi-bai-cua-toi')" class="d-flex align-items-baseline">-->
<!--              <img src="~/mlearn/icons/menu/profile/my-question.svg" alt="Hỏi bài của tôi" class="mr-2"/>-->
<!--              Hỏi bài của tôi-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="menu-item" :class="routeName === 'thu-vien-cua-toi' ? 'active': ''">-->
<!--            <div @click.prevent="goToPage('/thu-vien-cua-toi')" class="d-flex align-items-baseline">-->
<!--              <img src="~/mlearn/icons/menu/profile/my-library.svg" alt="Thư viện của tôi" class="mr-2"/>-->
<!--              Thư viện của tôi-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="menu-item" :class="routeName === 'lich-su-thanh-toan' ? 'active': ''">-->
<!--            <div @click.prevent="goToPage('/lich-su-thanh-toan')" class="d-flex align-items-baseline">-->
<!--              <img src="~/mlearn/icons/menu/profile/history-payment.svg" alt="Lịch sử thanh toán" class="mr-2"/>-->
<!--              Lịch sử thanh toán-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="menu-item line"></div>-->
<!--          <div class="menu-item pl-3" :class="routeName === 'doi-mat-khau' ? 'active': ''">-->
<!--            <div @click.prevent="goToPage('/doi-mat-khau')" class="d-flex align-items-baseline">-->
<!--              <img src="~/mlearn/icons/menu/profile/change-pass.svg" alt="Đổi mật khẩu" class="mr-2"/>-->
<!--              Đổi mật khẩu-->
<!--            </div>-->
<!--          </div>-->
          <div class="menu-item pl-3">
            <div @click.prevent="logout()" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/logout.svg" alt="Đăng xuất" class="mr-2"/>
              Đăng xuất
            </div>
          </div>
        </template>
        <template v-else>
          <div class="menu-item pl-3">
            <div @click="handleRedirectLogin">
              Đăng nhập
            </div>
          </div>
          <div class="menu-item pl-3">
            <div @click="handleRedirect">
              Đăng ký
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="menu-backdrop" v-if="showMenu" @click="closeMenu"></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {mapState} from 'vuex'
import Resource from '~/common/api/resource'
import {removeToken, removeVerify} from '~/common/utils/auth'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Profile from '~/components/mlearn/layout/menu/profile.vue'
import Notify from '~/components/mlearn/layout/menu/notify.vue'
// import ClassList from '~/components/mlearn/select/class.vue'
import {EventBus} from '~/common/utils/eventBus'

const profileResource = new Resource('profile/user')
// const classResource = new Resource('category/class')
export default {
  components: {
    Profile,
    Notify
    // ClassList
  },
  data () {
    return {
      NoAvatar,
      showMenu: false,
      showApp: true,
      interestedClass: '',
      isMobile: false,
      isDesktop: false,
      keyword: ''
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: 'ml-scrollbar'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    ...mapState([
      'authenticated'
    ]),
    homeActive () {
      const arr = ['index', 'hoi-bai', 'cau-hoi-slug', 'tra-loi-slug']
      return arr.includes(this.$route.name)
    },
    videoActive () {
      return /gioi-thieu/.test(this.$route.path)
    },
    documentActive () {
      return /tai-lieu/.test(this.$route.path)
    },
    quizActive () {
      return /trac-nghiem/.test(this.$route.path)
    },
    blogActive () {
      return /blog/.test(this.$route.path)
    },
    historyScore () {
      return /lich-su-cong-diem/.test(this.$route.path)
    },
    noiQuy () {
      const arr = ['huong-dan-cong-diem', 'noi-quy-hoi-bai', 'bang-xep-hang']
      return arr.includes(this.$route.name)
    },
    routeName () {
      return this.$route.name
    }
  },
  mounted () {
    const classId = Cookies.get('interestedClass') || this.user?.class_id
    this.interestedClass = classId || ''
    if (document.body.clientWidth < 1700) {
      this.isDesktop = true
    }
  },
  methods: {
    handleRedirect () {
      const path = this.$route.fullPath
      console.log('path', path)
      Cookies.set('redirect', path)
      this.$router.push('/user/register')
    },
    handleRedirectLogin () {
      const path = this.$route.fullPath
      console.log('path', path)
      Cookies.set('redirect', path)
      this.$router.push('/user/login')
    },
    goToPage (path = '/') {
      this.closeMenu()
      this.$router.push({path})
    },
    openMenu () {
      this.showMenu = true
      document.querySelector('body').classList.add('open-sidebar')
    },
    closeMenu () {
      if (this.showMenu) {
        this.showMenu = false
        document.querySelector('body').classList.remove('open-sidebar')
      }
    },
    findClass (classId) {
      Cookies.set('interestedClass', classId)
      location.reload()
    },
    updateClassInfo (id, item) {
      const {
        id: classId,
        name: classLevel
      } = item
      const data = {
        classLevel,
        classId
      }
      Cookies.set('interestedClass', classId)
      if (this.user != null) {
        profileResource.store(data)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Cập nhật lớp học thành công'
            })
            location.reload()
          })
      }
      EventBus.$emit('handleChangeClassMenu', item)
    },
    logout () {
      removeToken()
      removeVerify()
      Cookies.remove('flagAuto4G')
      window.location.href = '/'
    },
    searchData () {
      this.keyword = this.keyword.trim()
      if (this.keyword.length > 100) {
        this.keyword = this.keyword.substring(0, 100)
      }
      this.$router.push('/tim-kiem?keyword=' + encodeURIComponent(this.keyword))
    }
  }
}
</script>

<style lang="scss">
.mbl-menu {
  background-color: #004390;
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  @media (max-width: 576px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

  .dropdown-verify-class {
    min-width: 88px;
  }

  .mbl-menu-desktop {
    //padding: 17px 0 17px;
    padding: 0;
    .icon-mobile {
      font-size: 20px;
      color: #ffffff;
    }
    @media (max-width: 1366px) {
      //padding: 12px 0 12px;
    }
    @media (max-width: 992px) {
      padding: 12px 0 12px;
    }

    .logo {
      max-height: 42px;
      @media (max-width: 1366px) {
        max-height: 36px;
      }
      @media (max-width: 992px) {
        display: none;
      }
      @media (max-width: 576px) {
        max-height: 28px;
      }
    }

    .notify {
      margin-left: 15px;
      margin-right: 25px;
      @media (max-width: 1669px) {
        margin-left: 12px;
        margin-right: 18px;
      }
      @media (max-width: 1366px) {
        margin-left: 8px;
        margin-right: 10px;
      }
    }

    .list-menu {
      @media (max-width: 1366px) {}
      .menu-item {
        font-weight: 400;
        font-size: 18px;
        margin-right: 28px;
        color: var(--white);
        @media (max-width: 1669px) {
          margin-right: 20px;
        }
        @media (max-width: 1366px) {
          font-size: 16px;
          margin-right: 23px;
        }
        @media (max-width: 992px) {
          margin-right: 22px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.nuxt-link-exact-active {
          color: var(--white);
          font-weight: 500;
          padding: 26px 20px;
          font-size: 18px;
          @media (max-width: 1669px) {
            padding: 22px 17px;
          }
          @media (max-width: 1366px) {
            padding: 20px 14px;
            font-size: 16px;
          }
        }

        &.color-active {
          background-color: #ed1c24;
        }
      }

      .custom-toggle {
        display: flex;
        align-items: center;
        background-color: #004390;
        border: none;
        font-size: 18px;
        padding: 0;
        box-shadow: none;
        font-weight: 500;
        @media (max-width: 1669px) {
        }
        @media (max-width: 1366px) {
          font-size: 16px;
        }
        &:active, &:focus {
          background-color: #004390;
          box-shadow: none;
          border: none;
        }
        .text-active {
          color: #ED1C24;
          font-weight: 600;
        }

        .icon-active {
          color: #ED1C24;
        }

        i {
          margin-left: 5px;
          font-size: 12px;
        }
      }

      .custom-dropdown {
        margin: 25px 0 0 -39px;
        padding-bottom: 0;
        background-color: #004390;
        border-radius: 0 0 8px 8px;
        border: none;
        padding-top: 0;
        @media (max-width: 1669px) {
          margin: 22px 0 0 -34px;
        }
        @media (max-width: 1366px) {
          margin: 19px 0 0 -32px;
        }
        .custom-divider {
          padding: 0 20px;
          .dropdown-divider {
            margin: 0;
          }
        }

        .custom-dropdown-item {
          color: var(--white);

          a {
            .text-menu {
              //border-bottom: 2px solid #FAFAFA;
              @media (max-width: 1366px) {
              }
            }

            color: var(--white);
            padding: 10px 10px;

            &:hover {
              background-color: #78abd5;
            }
          }

          &:last-child:hover {
            a {
              border: none;
              background-color: #78abd5;
              border-radius: 0 0 8px 8px;
            }
          }

          &:last-child {
            a {
              border: none;
            }
          }
        }
      }
    }

    .register {
      margin: 0 7px;
      font-weight: 500;
      font-size: 16px;
      background-color: var(--white);
      border-radius: 18px;
      padding: 6px 24px;
      color: #004390;
      line-height: 29px;
      @media (max-width: 1669px) {
        padding: 4px 18px;
      }
      @media (max-width: 1366px) {
        padding: 0 14px;
        line-height: 36px;
      }
      @media (max-width: 992px) {
      }

      img {
        margin-right: 1rem;
        @media (max-width: 1366px) {
          margin-right: .7rem;
        }
        @media (max-width: 992px) {
        }
      }
    }

    .split {
      color: #CED4DA;
      @media (max-width: 1366px) {
      }
      @media (max-width: 992px) {
      }
    }

    .login {
      line-height: 29px;
      font-weight: 500;
      font-size: 16px;
      background-color: #004390;
      border-radius: 18px;
      border: 1px solid var(--white);
      padding: 6px 18px;
      color: var(--white);
      @media (max-width: 1669px) {
        padding: 4px 18px;
      }
      @media (max-width: 1366px) {
        padding: 0 16px;
        line-height: 36px;
      }
      @media (max-width: 992px) {
      }
    }
  }

  .mbl-menu-mobile {
    background: #004390;
    position: fixed;
    width: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    transition: all .4s ease;
    padding: 0;

    &.to-left {
      right: 0;

      &.open {
        width: 240px;
      }
    }

    &.open {
      .list-menu {
        display: block;
        .menu-item {
          &.color-active {
            background-color: #ed1c24;
          }
          .custom-icon-down {
            font-size: 12px;
          }
          a {
            font-weight: 500;
            font-size: 16px;
            color: var(--white);
          }
        }
        .custom-btn-collapse {
          background-color: #004390;
          color: var(--white);
          border: none;
          box-shadow: none;
          .text-active {
            color: #ed1c24;
            font-weight: 600;
          }

          .icon-active {
            color: #ed1c24;
          }
        }
        .custom-collapse {
          font-weight: 500;
          font-size: 14px;
          color: var(--white);
          padding: 0 28px;
          .item-collapse {
            display: flex;
            align-items: baseline;
          }
        }
      }
    }

    .close-menu {
      right: 16px;
      top: 10px;
      font-size: 20px;
      color: var(--white);
    }

    .list-menu {
      display: none;
      padding-top: 40px;
      padding-bottom: 70px;

      .menu-item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        color: var(--white);

        &.menu {
          padding-left: 16px;
          padding-right: 16px;

          &.active {
            color: var(--white);
            background: #004390;
            border-radius: 8px;
          }
        }

        &.line {
          padding-top: 0;
          padding-bottom: 0;
          margin: 8px 0;
          border: 1px solid #EBEBEB;
        }

        &.active {
          color: #004390;
        }

        &:hover {
          background: #004390;
          color: var(--white);
          border-radius: 8px;
        }

        .avatar {
          width: 46px;
          height: 46px;
        }

        .content {
          margin-left: 5px;

          .user-name {
            font-weight: 500;
            font-size: 16px;
            max-width: 150px;
          }

          .user-info {
            font-size: 14px;
            color: #CCCCCC;
          }
        }
      }
    }
  }

  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4000;
    background-color: #000;
    opacity: .5;
  }

  .position-icon {
    background: #ebebeb;
    padding: 7px;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
    position: absolute;
    top: 0px;
    left: -18px;
    font-size: 18px;
    color: #004390;
    @media (max-width: 1366px) {
      left: -15px;
      padding: 5px;
    }
  }

  .group-desktop {
    @media (max-width: 992px) {
      display: none;
      color: #333333;
    }
  }

  .input-search {
    padding: 8px 0px 9px 20px;
    background-color: #EBEBEB;
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
    @media (max-width: 1669px) {
      padding: 8px 0px 9px 20px;
    }
    @media (max-width: 1366px) {
      padding: 6px 0px 7px 18px;
    }
    //@media (max-width: 1366px) {
    //  padding: 4px 0 4px 4px;;
    //}

    &::placeholder {
      color: #004390;
      //padding-left: 14px;
      //@media (max-width: 1669px) {
      //  padding-left: 14px;
      //}
      //@media (max-width: 1366px) {
      //  padding-left: 32px;
      //}
    }
  }
}
</style>
