<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-xl-4">
          <div class="company-info">
            <div class="mb-4 d-flex justify-content-center">
              <img alt="logo" src="~/mlearn/icons/menu/logo-mobi30-white.png" class="logo pointer" @click="goToPage('/')">
            </div>
            <div class="name text-center text-uppercase">Trung tâm Dịch vụ số MobiFone</div>
            <div class="text-uppercase text-center">Chi nhánh Tổng Công ty Viễn thông MobiFone</div>
            <!--            <div><span>Địa chỉ: </span>Số 1 Phạm Văn Bạch, Phường Yên Hòa, Quận Cầu Giấy, Hà Nội</div>-->
            <!--            <div><span>Giấy chứng nhận ĐKKD: </span>0100686209-087 do Sở KHĐT thành phố Hà Nội<br>Cấp lần đầu ngày 29/10/2008<br>Thay đổi lần thứ 6 ngày 16/06/2023</div>-->
            <!--            <div><span>Chịu trách nhiệm nội dung: </span>Ông Lê Quang Minh - Giám đốc Trung Tâm</div>-->
            <!--            <div><span>Hợp tác phát triển và vận hành sản phẩm: </span>Công Ty Cổ Phần Công Nghệ - Viễn Thông ELCOM</div>-->
            <!--            <div class="d-none d-lg-block"><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div>-->
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-1 col-xl-1 line-mobile">
          <div class="block-data">
            <div class="title mb-2">Dịch vụ</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/hoi-bai">Hỏi đáp</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/chat-ai">Chat AI</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/blog">Blog</nuxt-link>
              </li>
              <!--              <li class="item">-->
              <!--                <nuxt-link to="/">Dịch vụ nằm trong hệ sinh thái mobiedu.vn</nuxt-link>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title mb-2">Chính sách</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=dieu-khoan-su-dung">Điều khoản sử dụng</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=chinh-sach-bao-mat">Chính sách bảo mật</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/huong-dan-su-dung">Hướng dẫn sử dụng</nuxt-link>
              </li>
              <!--              <li class="item">-->
              <!--                <nuxt-link to="/">Dịch vụ nằm trong hệ sinh thái mobiedu.vn</nuxt-link>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-2 col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title mb-2">Thông tin</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/gioi-thieu">Giới thiệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/noi-quy-hoi-bai">Nội quy</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/huong-dan-cong-diem">Cơ chế cộng điểm</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/bang-xep-hang">Bảng xếp hạng</nuxt-link>
              </li>
              <!--              <li class="item">-->
              <!--                <nuxt-link to="/tai-lieu">Tài liệu</nuxt-link>-->
              <!--              </li>-->
              <!--              <li class="item">-->
              <!--                <nuxt-link to="/trac-nghiem">Bộ đề</nuxt-link>-->
              <!--              </li>-->
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-3 order-lg-last col-xl-3 line-mobile">
          <div class="block-data">
            <!--            <div class="title">HỖ TRỢ KHÁCH HÀNG</div>-->
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <!--                <img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 1900866648-->
                <span class="title">Tel: </span> 0866698676
              </li>
              <li class="item">
                <!--                <img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 1900866648-->
                <span class="title">Email: </span>contact@mobiedu.vn
              </li>
              <li class="item">
                <!--                <img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 1900866648-->
                <span class="title">Địa chỉ: </span>Số 1 Phạm Văn Bạch, Phường Yên Hòa, Quận Cầu Giấy, Hà Nội
              </li>
            </ul>
          </div>
          <!--          <div class="block-data d-none d-xl-block">-->
          <!--            <div class="title">TẢI ỨNG DỤNG MLEARN</div>-->
          <!--            <ul class="list-item list-unstyled">-->
          <!--              <li class="item form-data">-->
          <!--                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.svg" class="qrcode">-->
          <!--                <div class="content platform">-->
          <!--                  <div>-->
          <!--                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay w-100">-->
          <!--                  </div>-->
          <!--                  <div class="pt-1">-->
          <!--                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore w-100">-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </li>-->
          <!--              <li class="item note">Quét mã QR code để cài đặt</li>-->
          <!--            </ul>-->
          <!--          </div>-->
        </div>
        <!--        <div class="col-md-6 col-lg-3 col-xl-2 d-block d-xl-none line-mobile">-->
        <!--          <div class="block-data">-->
        <!--            <div class="title">TẢI ỨNG DỤNG MLEARN</div>-->
        <!--            <ul class="list-item list-unstyled mb-0">-->
        <!--              <li class="item form-data">-->
        <!--                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.svg" class="qrcode">-->
        <!--                <div class="content platform">-->
        <!--                  <div>-->
        <!--                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay">-->
        <!--                  </div>-->
        <!--                  <div class="pt-2">-->
        <!--                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore">-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="d-block d-lg-none align-self-end"><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div>-->
        <!--              </li>-->
        <!--              <li class="item note">Quét mã QR code để cài đặt</li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <!--      <div class="row justify-content-end">-->

      <!--       -->
      <!--      </div>-->
      <div class="row">
        <div class="group-icon offset-md-8 offset-lg-9 col-6 col-md-4 col-lg-3">
          <a href="https://www.facebook.com/mobiedu.vn/" target="_blank">
            <i class="fa fa-facebook-square custom-fb"></i>
          </a>
          <a href="https://www.youtube.com/@mobiEdu_MBF" target="_blank">
            <i class="fa fa-youtube-play custom-youtube"></i>
          </a>
        </div>
        <div class="col-md-10 group-info">
          <p>Giấy ĐKKD: 0100686209-087 do Sở KHĐT thành phố Hà Nội cấp lần đầu ngày 29/10/2008, thay đổi lần thứ 6 ngày 16/06/2023</p>
          <p>Chịu trách nhiệm nội dung: Ông Lê Quang Minh - Giám đốc Trung tâm</p>
          <p>Phát triển và vận hành sản phẩm: Công ty cổ phần công nghệ viễn thông ELCOM</p>
        </div>
        <div class="col-6 col-md-2 order-first order-md-last">
          <img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify">
        </div>
      </div>
      <div class="line"></div>
      <div class="copy-right"><img alt="copy-right" src="~/mlearn/icons/footer/copy-right.svg"> Copyright 2023 - MobiFone</div>
    </div>
    <template v-if="!user">
<!--      <ModalLogin/>-->
<!--      <ModalRegister/>-->
<!--      <ModalForget/>-->
      <ModalLoginNow/>
    </template>
    <ModalQuestion/>
    <ModalRedirectSub/>
    <ModalVerifyGuru v-if="!isVerifiedMobile"/>
<!--    <ModalVerifyMobile/>-->
<!--    <ModalVerifyClass/>-->
    <ModalSuggestSub/>
    <BackToTop :right="isMobile ? '10%' : '50px'"/>
    <ChatAi />
  </footer>
</template>

<script>
// import ModalForget from '~/components/mlearn/modal/forget.vue'
// import ModalLogin from '~/components/mlearn/modal/login.vue'
import ModalLoginNow from '~/components/mlearn/modal/login-now.vue'
// import ModalRegister from '~/components/mlearn/modal/register.vue'
import ModalQuestion from '~/components/mlearn/modal/add-question.vue'
import ModalRedirectSub from '~/components/mlearn/modal/sub.vue'
import ModalVerifyGuru from '~/components/mlearn/modal/verify-guru.vue'
// import ModalVerifyMobile from '~/components/mlearn/modal/verify-mobile.vue'
// import ModalVerifyClass from '~/components/mlearn/modal/verify-class.vue'
import ModalSuggestSub from '~/components/mlearn/modal/suggest-sub.vue'

export default {
  components: {
    // ModalLogin,
    // ModalRegister,
    // ModalForget,
    ModalQuestion,
    ModalRedirectSub,
    // ModalVerifyMobile,
    ModalVerifyGuru,
    // ModalVerifyClass,
    ModalSuggestSub,
    ModalLoginNow
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    isVerifiedMobile () {
      return this.$store.state.isVerifiedMobile
    }
  },
  methods: {
    goToPage (path = '/') {
      this.$router.push({path})
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  margin-top: auto;
  background: #004390;
  color: rgba(255, 255, 255, 0.8);
  padding: 28px 0 15px;
  @media (max-width: 576px) {
    padding: 15px 0 10px;
  }

  .logo {
    max-height: 42px;
    @media (max-width: 576px) {
      max-height: 36px;
    }
  }

  .row {
    .group-icon {
      //margin: 12px 0;
      a {
        .custom-fb {
          font-size: 24px;
          margin: 0 10px;
          color: var(--white);
        }
        .custom-youtube {
          font-size: 26px;
          color: var(--white);
        }
      }
    }
    .line-mobile {
      @media (max-width: 576px) {
        border-top: 1px solid rgba(255, 255, 255, .2);
      }
       @media (max-width:991px) {
         margin-top: 12px;
       }
    }

    > [class*="col-"] {
      //padding-top: 2rem;
      @media (max-width: 576px) {
        padding-top: 1rem;
      }

      .company-info {
        padding-right: 50px;
        @media (max-width: 1366px) {
          padding-right: unset;
        }
        @media (max-width: 576px) {
          padding-right: unset;
        }

        > div {
          color: var(--white);
          font-size: 14px;
          white-space: break-spaces;
          margin-bottom: 5px;
          @media (max-width: 576px) {
            margin-bottom: 8px;
          }

          &.name {
            color: var(--white);
            font-size: 16px;
            font-weight: 700;
            @media (max-width: 576px) {
              font-size: 14px;
            }
          }

          span {
            font-weight: bold;
            color: var(--white);
            @media (max-width: 576px) {
            }
          }

          .notify {
            max-height: 60px;
            @media (max-width: 576px) {
              max-height: 40px;
            }
          }
        }
      }

      .block-data {
        font-weight: 400;
        margin-top: 35px;
        font-size: 16px;
        @media (max-width: 576px) {
          margin-top: 25px;
        }

        &:first-child {
          margin-top: 0;
          @media (max-width: 576px) {
          }
        }

        .title {
          font-weight: 700;
          color: var(--white);
          //text-transform: uppercase;
          font-size: 16px;
          margin-bottom: 18px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }

        .list-item {
          margin-bottom: 0;
          @media (max-width: 576px) {
            white-space: nowrap;
          }

          .item {
            font-weight: 400;
            font-size: 16px;
            color: rgba(255, 255, 255, .8);
            //margin-bottom: 8px;
            @media (max-width: 576px) {
              display: inline-block;
              margin-right: 12px;
              &:last-child {
                margin-right: 0;
              }
            }

            &:last-child {
              // margin-bottom: 0;
              @media (max-width: 576px) {
              }
            }

            &.note {
              font-style: italic;
              font-weight: normal;
              font-size: 12px;
              color: rgba(255, 255, 255, .6);
              @media (max-width: 576px) {
              }
            }

            a {
              font-weight: 400;
              font-size: 16px;
              color: rgba(255, 255, 255, .8);
            }

            .platform {
              margin-left: 10px;
              @media (max-width: 576px) {
              }

              img {
                margin-bottom: 10px;
                @media (max-width: 576px) {
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .notify {
              max-width: 120px;
            }
          }
        }
      }
    }
  }

  .group-info {
    padding-left: 34px;
    margin-top: 14px;
    @media (max-width: 992px){
      padding-left: 6px;
    }
  }

  .order-first {
    .notify {
      padding-top: 15px;
    }
  }

  //.line {
  //  height: 1px;
  //  margin: 12px 0;
  //  background: rgba(255, 255, 255, .2);
  //  @media (max-width: 576px) {
  //  }
  //}

  .copy-right {
    font-size: 16px;
    color: rgba(255, 255, 255, .6);
    margin-left: 30px;
    margin-top: 8px;
    @media (max-width: 992px){
      margin-left: 0px;
    }
  }
}
</style>
