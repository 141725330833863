<template>
  <b-dropdown @show="clickNotify" variant="link" right toggle-class="p-0 shadow-none" no-caret class="menu-notification">
    <template #button-content>
      <img :src="numberNewNotify ? require('~/assets/mlearn/icons/menu/profile/notify.svg') : require('~/assets/mlearn/icons/menu/profile/notify-default.svg')" alt="notify" class="img-notify" />
    </template>
    <NoData class="no-data" v-if="!listNotify.length && !loading"/>
    <div class="noti-content scrollbar">
      <div class="item-noti form-data" v-for="(item, ind) in listNotify" :key="ind">
        <img :src="checkAvatarNotify(item)" alt="notify" class="avatar rounded-circle" @click="redirectAction(item)" />
        <div class="content" :class="{'pointer': (item.url && item.url !== '#')}">
          <div class="content-noti" @click="redirectAction(item)">{{ item.text | htmlFilter }}</div>
          <div class="time-ago">{{ item.timeRequest | ralativeTime }}</div>
        </div>
      </div>
      <client-only>
        <scroll-loader :loader-method="getListNotify" :loader-disable="!loadMore">
          <b-spinner class="m-5" label="Busy"></b-spinner>
        </scroll-loader>
      </client-only>
    </div>
  </b-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import Resource from '~/common/api/resource'
import { stripHtml } from '~/common/utils'
import { readAllNotify } from '~/common/api/notify'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
const notifyUserApi = new Resource('notify/get-notify-user')
export default {
  filters: {
    htmlFilter (html) {
      return stripHtml(html)
    }
  },
  data () {
    return {
      listNotify: [],
      loadMore: true,
      offset: 0,
      limit: 10,
      loading: false,
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'numberNewNotify',
      'user'
    ])
  },
  methods: {
    checkAvatarNotify (item) {
      let objectType = item.objectType
      objectType = parseInt(objectType)
      switch (objectType) {
        case 104:
          return '/avatar-guru.png'
        case 105:
        case 107:
        case 111:
        case 112:
        case 113:
          return '/images/colearn.png'
        default:
          return item.avatar || this.NoAvatar
      }
    },
    redirectAction (item) {
      if (item.url && item.url !== '#') {
        location.href = item.url
      }
    },
    getListNotify () {
      if (this.loading || !this.user) { return }
      const param = {
        offset: this.offset,
        limit: this.limit,
        level: 1
      }
      this.loading = true
      notifyUserApi.list(param)
        .then((response) => {
          if (response.data && response.status === 200) {
            this.$store.dispatch('setNumberNewNotify', response.data.countNotiNotRead)
            this.listNotify.push(...response.data.listNoti)
            this.loadMore = response.total > this.listNotify.length
            this.offset = this.offset + this.limit
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    clickNotify () {
      if (this.user) {
        readAllNotify(this.user.uuid)
      }
      this.offset = 0
      this.listNotify = []
      this.getListNotify()
    }
  }
}
</script>

<style lang="scss">
.menu-notification{
  .img-notify {
    width: 22px;
    height: 27px;
    @media (max-width:1669px) {
      width: 20px;
      height: 25px;
    }
  }
  .dropdown-menu{
    min-width: 320px;
    @media (max-width:1366px) {
      min-width: 250px;
    }
    @media (max-width:992px) {}
    @media (max-width:576px) {
      min-width: 200px;
    }
    .noti-content{
      max-height: 300px;
      @media (max-width:1366px) {
        max-height: 200px;
      }
      @media (max-width:992px) {}
      @media (max-width:576px) {
        max-height: 200px;
      }
      .item-noti{
        padding:10px 10px 0;
        @media (max-width:1366px) {
          padding:7px 7px 0;
        }
        @media (max-width:992px) {}
        @media (max-width:576px) {
          padding:5px 5px 0;
        }
        .avatar{
          width: 40px;
          height: 40px;
          margin-right: 10px;
          @media (max-width:1366px) {
            width: 36px;
            height: 36px;
            margin-right: 7px;
          }
          @media (max-width:992px) {}
          @media (max-width:576px) {
            width: 30px;
            height: 30px;
            margin-right: 5px;
          }
        }
        .content{
          .content-noti{
            font-size: 14px;
          }
          .time-ago{
            margin:5px 0;
            color: #92acc2;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
