<template>
  <div class="user-profile" v-if="user">
    <span class="mr-2 text-white name-user">{{ user.fullName }}</span>
    <b-dropdown right variant="white" menu-class="custom-dropdown" toggle-class="custom-toggle text-decoration-none px-0 d-flex align-items-center shadow-none p-0" no-caret class="box-user-info" @hide="showSubItem()">
      <template #button-content class="mt-0">
        <el-image class="avatar-thumbnail rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar"></el-image>
      </template>
      <template v-if="!subActive">
        <b-dropdown-item class="custom-dropdown-item">
          <nuxt-link to="/hoi-bai-cua-toi" class="menu-item text-menu">Hỏi bài của tôi</nuxt-link>
        </b-dropdown-item>
        <b-dropdown-divider class="custom-divider"></b-dropdown-divider>
        <b-dropdown-item class="custom-dropdown-item">
          <nuxt-link to="/thong-tin-ca-nhan" class="menu-item text-menu">Thông tin tài khoản</nuxt-link>
        </b-dropdown-item>
        <b-dropdown-divider class="custom-divider"></b-dropdown-divider>
        <b-dropdown-item class="custom-dropdown-item">
          <nuxt-link to="/lich-su-cong-diem" class="menu-item text-menu">Lịch sử cộng điểm</nuxt-link>
        </b-dropdown-item>
        <b-dropdown-divider class="custom-divider"></b-dropdown-divider>
        <b-dropdown-item class="custom-dropdown-item" @click="logout()">
          Đăng xuất
        </b-dropdown-item>
      </template>
      <!--      <template v-if="!subActive">-->
      <!--        <b-dropdown-item class="item-profile-menu" to="/thong-tin-ca-nhan" @click.native.capture.stop>-->
      <!--          <div class="form-data">-->
      <!--            Thông tin tài khan-->
      <!--          </div>-->
      <!--        </b-dropdown-item>-->
      <!--        <b-dropdown-item class="item-profile-menu" to="/hoi-bai-cua-toi">-->
      <!--          &lt;!&ndash;          <img src="~/mlearn/icons/menu/profile/my-question.svg" alt="Hỏi bài của tôi" class="mr-2"/>&ndash;&gt;-->
      <!--          Hỏi bài của tôi-->
      <!--        </b-dropdown-item>-->
      <!--        &lt;!&ndash;        <b-dropdown-item class="item-profile-menu" @click.native.capture.stop="showSubItem('my-library')">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="~/mlearn/icons/menu/profile/my-library.svg" alt="Thư viện của tôi" class="mr-2"/>&ndash;&gt;-->
      <!--        &lt;!&ndash;          Thư viện của tôi&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="~/mlearn/icons/menu/profile/arrow-right.svg" alt="Xem" class="has-sub position-center"/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->
      <!--        <b-dropdown-divider></b-dropdown-divider>-->
      <!--        &lt;!&ndash;        <b-dropdown-item class="item-profile-menu logout" to="/doi-mat-khau">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="~/mlearn/icons/menu/profile/change-pass.svg" alt="Đổi mật khẩu" class="mr-2"/>&ndash;&gt;-->
      <!--        &lt;!&ndash;          Đổi mật khẩu&ndash;&gt;-->
      <!--        &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->
      <!--        <b-dropdown-item class="item-profile-menu logout" @click="logout()">-->
      <!--          &lt;!&ndash;          <img src="~/mlearn/icons/menu/profile/logout.svg" alt="Đăng xuất" class="mr-2"/>&ndash;&gt;-->
      <!--          Đăng xuất-->
      <!--        </b-dropdown-item>-->
      <!--      </template>-->
      <template v-if="subActive === 'my-library'">
        <b-dropdown-item class="item-sub-menu" @click.native.capture.stop="showSubItem()">
          <div class="form-data align-items-center">
            <img src="~/mlearn/icons/menu/profile/back.svg" alt="Quay lại" class="mr-2"/>
            <div class="content">Quay lại</div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item class="item-sub-menu" to="/thu-vien-cua-toi?tab=1">
          <img src="~/mlearn/icons/menu/profile/my-video.svg" alt="Khóa học của tôi" class="mr-2"/>
          Khóa học của tôi
        </b-dropdown-item>
        <b-dropdown-item class="item-sub-menu" to="/thu-vien-cua-toi?tab=2">
          <img src="~/mlearn/icons/menu/profile/my-document.svg" alt="Tài liệu của tôi" class="mr-2"/>
          Tài liệu của tôi
        </b-dropdown-item>
        <b-dropdown-item class="item-sub-menu" to="/thu-vien-cua-toi?tab=3">
          <img src="~/mlearn/icons/menu/profile/my-quiz.svg" alt="Bộ đề của tôi" class="mr-2"/>
          Bộ đề của tôi
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>
<script type="text/javascript">
import {mapState} from 'vuex'
import Cookies from 'js-cookie'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import {removeToken, removeVerify} from '~/common/utils/auth'

export default {
  data () {
    return {
      NoAvatar,
      subActive: ''
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  methods: {
    logout () {
      removeToken()
      removeVerify()
      Cookies.remove('flagAuto4G')
      window.location.href = '/'
    },
    showSubItem (type = '') {
      this.subActive = type
    }
  }
}
</script>
<style lang="scss">
.user-profile {
  .name-user{
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
  }
  .box-user-info {
    .avatar-thumbnail {
      width: 36px;
      height: 36px;
      object-fit: cover;
      @media (max-width: 992px) {
      }
      @media (max-width: 1366px) {
      }
    }

    //ul {
    //  margin: 0;
    //  padding: 10px 16px;
    //  min-width: 188px;
    //  @media (max-width: 1366px) {
    //    padding: 8px 12px;
    //    min-width: 250px;
    //  }
    //  @media (max-width: 992px) {
    //  }
    //
    //  .item-profile-menu, .item-sub-menu {
    //    padding: 8px 0;
    //    color: var(--white);
    //
    //    &:first-child {
    //      padding-top: 10px;
    //    }
    //
    //    &:last-child {
    //      padding-bottom: 10px;
    //    }
    //
    //    .dropdown-item {
    //      position: relative;
    //
    //      &:hover, &:focus {
    //        background: #78abd5;
    //      }
    //
    //      .has-sub {
    //        right: 0;
    //      }
    //    }
    //
    //    .form-data {
    //      padding-bottom: 16px;
    //      border-bottom: 1px solid #EBEBEB;
    //
    //      .avatar {
    //        width: 68px;
    //        height: 68px;
    //        margin-right: 16px;
    //        @media (max-width: 1366px) {
    //          width: 48px;
    //          height: 48px;
    //          margin-right: 10px;
    //        }
    //        @media (max-width: 992px) {
    //        }
    //      }
    //
    //      .user-name {
    //        font-weight: 500;
    //        font-size: 16px;
    //        max-width: 150px;
    //        @media (max-width: 1366px) {
    //        }
    //        @media (max-width: 992px) {
    //        }
    //      }
    //
    //      .user-info {
    //        font-size: 14px;
    //        color: #868789;
    //      }
    //    }
    //
    //    a, button {
    //      padding: 0;
    //
    //      &.nuxt-link-exact-active {
    //        color: #004390;
    //      }
    //    }
    //
    //    img {
    //      margin-right: 5px;
    //    }
    //  }
    //}
  }

  .custom-dropdown {
    margin: 20px 0 0 105px;
    padding-bottom: 0;
    background-color: #004390;
    border-radius: 0 0 8px 8px;
    border: none;
    padding-top: 0;
    @media (max-width: 1669px) {
      margin: 18px 0 0 105px;
    }
    @media (max-width: 1366px) {
      margin: 12px 0 0 105px;
    }
    .custom-divider {
      padding: 0 20px;
      .dropdown-divider {
        margin: 0;
      }
    }

    .custom-dropdown-item {
      color: var(--white);

      a {
        .text-menu {
          //border-bottom: 2px solid #FAFAFA;
        }

        color: var(--white);
        padding: 10px 10px;

        &:hover {
          background-color: #78abd5;
        }
      }

      &:last-child:hover {
        a {
          border: none;
          background-color: #78abd5;
          border-radius: 0 0 8px 8px;
        }
      }
      &:last-child {
        a {
          padding: 10px 20px;
          border: none;
        }
      }
    }
  }
}
</style>
